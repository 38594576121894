import { Brand } from './typings'

const stash: Brand = {
  clientInfo: {
    displayName: 'Stash Hotel Rewards®',
    accountNumberLengths: [16],
    footerDisplay: 'stashrewards.com',
    footerLink: 'https://www.stashrewards.com/',
    applicationUrl: '',
    dsecApigeeClientId: {
      development: 'ukuAB29OYGU6PnVSSEJIwL7r8CrDokvR',
      production: 'rMxzMvqw87DOYIvdajb9NNgyogN45FK7'
    },
    persistApigeeClientId: { development: '', production: '' },
    setpayApigeeClientId: {
      development: '',
      production: ''
    },
    metaTagCardTitle: {
      en: 'Credit Card',
      es: 'Tarjeta de Crédito'
    },
    metaTagCardDescription: {
      en: `Log into your Stash Hotel Rewards® credit card account online to pay your bills, check your FICO score, sign up for paperless billing, and manage your account preferences`,
      es: `Ingrese a su cuenta de la Tarjeta de Crédito Stash Hotel Rewards® en línea para pagar sus facturas, revisar su puntuación FICO, inscribirse en facturas digitales y manejar su cuenta.`
    },
    languages: ['en'],
    vanityUrl: 'stashrewards.com',
    hostname: 'stashrewards.syf.com',
    linkingRedirect: '',
    ivaKey: '',
    hasMss: true,
    hasCpl: false,
    promoUrl: '',
    hasCplOldErrorCode: false,
    isMultiAccount: false,
    footerContent: {
      en: 'Your Stash Hotel Rewards® Visa Signature® Card or Stash Hotel Rewards® Platinum Visa® Card is issued by Synchrony Bank. The Synchrony Bank Privacy Policy governs the use of the Stash Hotel Rewards® Visa Signature® Card or Stash Hotel Rewards® Platinum Visa® Card. The use of this site is governed by the use of the Synchrony Bank Internet Privacy Policy, which is different from the privacy policy of Stash Hotel Rewards®.',
      es: ''
    },
    phoneNumbers: {
      loan: '',
      dualcard: '1-844-822-5911',
      fraud: '1-855-485-6325',
      customerService: '1-844-822-5911',
      plcc: '1-855-485-6325'
    },
    iOSAppId: '',
    androidApp: {
      id: '',
      author: '',
      title: '',
      image: ''
    },
    crossBorder: false,
    cplHeroBannerDisplayName: '',
    termsOfService: {
      en: '/essimages/cs/groups/ess_webasset/dualcard/@stash/en/html/ess016942.html',
      es: ''
    },
    onlinePrivacy: {
      en: '/essimages/cs/groups/ess_webasset/dualcard/@stash/en/html/ess019075.html',
      es: ''
    },
    privacyPolicy: {
      en: '/essimages/cs/groups/ess_webasset/dualcard/@stash/en/html/ess016747.html',
      es: ''
    },
    websiteUsage: {
      en: '/essimages/cs/groups/ess_webasset/dualcard/@stash/en/html/ess016749.html',
      es: ''
    },
    voice: {
      cardName: '',
      google: {
        termsAndConditionsLink: ''
      },
      alexa: {
        termsAndConditionsLink: ''
      }
    }
  },
  palette: {
    field: {
      icon: '#333333',
      focus: '#34657F',
      label: ''
    },
    progressStepBar: {
      fill: '#333333',
      label: '#333333'
    },
    progressBar: {
      complete: '',
      error: '#c63527',
      inProgress: '#0072ad',
      background: '#e9eaeb'
    },
    button: {
      primary: {
        text: '#FFFFFF',
        hover: '#E3AA39'
      },
      secondary: {
        hover: '#e5efff'
      },
      background: '#E48B25'
    },
    checkbox: {
      background: '#E48B25',
      font: '#FFFFFF'
    },
    switch: { background: '' },
    heroBackground: '#343642',
    bodyBackground: '#FFFFFF',
    iconBackground: '#CACACA',
    headerBackground: '#FFFFFF',
    modalBackground: '#ffffff',
    formBackground: '',
    headerDivider: '#343642',
    footerDivider: '#FFFFFF',
    contrastingText: '#ffffff',
    bodyText: '#333333',
    languageSelector: '#ffffff',
    focus: '#003d50',
    link: '#E48B25',
    footer: {
      background: '#262E32',
      text: '#FFFFFF',
      link: '#FFFFFF'
    }
  },
  images: {
    logo: {
      en: '/assets/synchrony/logo.svg',
      es: '/assets/synchrony/logo.svg'
    },
    hero: '',
    heroBgDesktop: '',
    heroBgMobile: '',
    mainPromo: {
      en: '/assets/stash/promo.png',
      es: '/assets/stash/promo.png'
    },
    voiceCplLogo: ''
  }
}

export default stash
